const dataNews = [

    {
        id: 53,
        img: "053.png",
        date: "Belgrade, June 2025",
        content: "ETH Belgrade",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7310613185437290496"
    },
    {
        id: 52,
        img: "052a.png",
        date: "Online, April 2025",
        content: "Webinar",
        link: "https://www.linkedin.com/events/thenewstandardfortelecomops-how7309910678335610881/"
    },
    {
        id: 51,
        img: "051a.jpg",
        date: "Jahorina, March 2025",
        content: "IEEE INFOTEH-JAHORINA",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7308809041080709120"
    },
    {
        id: 50,
        img: "050a.JPG",
        date: "Denver, February 2025",
        content: "ETHDenver 2025",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7300802482434199552"
    },
    {
        id: 49,
        img: "049.png",
        date: "Novi Sad, December 2024",
        content: "Happy Holidays!",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7277635140757983232"
    },
    {
        id: 48,
        img: "048.png",
        date: "Novi Sad, December 2024",
        content: "UNIDO AIM Global Forum",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7271180966222561280"
    },
    {
        id: 47,
        img: "047.png",
        date: "Novi Sad, December 2024",
        content: "Armada JS",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7270520255104352259"
    }, 
    {
        id: 46,
        img: "046.png",
        date: "Novi Sad, December 2024",
        content: "EEA Membership",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7270025279127867392"
    },
    {
        id: 45,
        img: "045.png",
        date: "Novi Sad, December 2024",
        content: "IEEE Blockchain",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7270090781376274433"
    },
    {
        id: 44,
        img: "044.png",
        date: "Belgrade, December 2024",
        content: "TELFOR 2024",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7269061665755746304"
    },
    {
        id: 43,
        img: "043.png",
        date: "Belgrade, December 2024",
        content: "Mathematical Institute SANU",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7269030698563342336"
    },
    {
        id: 42,
        img: "042.png",
        date: "Belgrade, November 2024",
        topic: "Conference",
        content: "DSC Europe 24",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7265047932301623297"
    },
    {
        id: 41,
        img: "041.jpg",
        date: "Zagreb, November 2024",
        topic: "Conference",
        content: "Leaders of Trade",
        link: "https://www.linkedin.com/posts/skylinebridge_conferences-business-business-activity-7264951585233850368-YzHW?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 40,
        img: "040a.png",
        date: "Belgrade, November 2024",
        topic: "Conference",
        content: "Cryptica Conference 24",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7265783984326946816"
    }, 
    {
        id: 39,
        img: "039.jpg",
        date: "Bangkok, November 2024",
        topic: "Conference",
        content: "Devcon 2024",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7262511537444024322"
    }, 
    {
        id: 38,
        img: "038a.jpg",
        date: "Singapore, November 2024",
        topic: "Conference",
        content: "Insights Forum\u2122 Elevandi",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7259251362822045696"
    }, 
    {
        id: 37,
        img: "037b.jpg",
        date: "Belgrade, October 2024",
        topic: "Conference",
        content: "Splet Tech Conference",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7249707250842308609"
    }, 
    {
        id: 36,
        img: "036.jpg",
        date: "Singapore, September 2024",
        topic: "Conference",
        content: "TOKEN2049 Singapore",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7242498133488713728"
    }, 
    {
        id: 35,
        img: "035.jpg",
        date: "Brussels, July 2024",
        topic: "Conference",
        content: "Ethereum Community Conference [7]",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7216545314571173890"
    },
    {
        id: 34,
        img: "034.jpg",
        date: "Novi Sad, June 2024",
        topic: "Conference",
        content: "Industry 4.0 in Western Balkans",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7207792141199097856"
    },
    {
        id: 33,
        img: "033.jpg",
        date: "Athens, June 2024",
        topic: "Offsite",
        content: "Ethernal team in Athens",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7206325166702768128"
    },
    {
        id: 32,
        img: "032.jpg",
        date: "Belgrade, June 2024",
        topic: "Conference",
        content: "Rust Summit",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7204463442919837696"
    },
    {
        id: 31,
        img: "031.png",
        date: "Novi Sad, June 2024",
        topic: "Research",
        content: "Ethereum EVM JIT",
        link: "https://medium.com/@Ethernal.tech/ethereum-evm-jit-outline-98503362cb5c"
    },
    {
        id: 30,
        img: "030.png",
        date: "Belgrade, June 2024",
        topic: "Conference",
        content: "ETH Belgrade",
        link: "https://www.linkedin.com/posts/eth-belgrade_new-speaker-announcement-excited-to-announce-activity-7201965296205647873-lP3u?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 29,
        img: "029b.png",
        date: "Belgrade, June 2024",
        topic: "Conference",
        content: "ETH Belgrade",
        link: "https://www.linkedin.com/posts/eth-belgrade_new-speaker-announcement-were-delighted-activity-7201130564366536704-KzSx?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 28,
        img: "028.png",
        date: "Belgrade, June 2024",
        topic: "Conference",
        content: "ETH Belgrade",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7195789579407228929"
    },
    {
        id: 27,
        img: "027.png",
        date: "Novi Sad, April 2024",
        topic: "Partnership",
        content: "Ethernal Apex Fusion partnership",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7189641445480296448"
    },
    {
        id: 26,
        img: "026.jpg",
        date: "Dubai, April 2024",
        topic: "Conference",
        content: "TOKEN2049 Dubai",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7184621179209404416"
    },

    {
        id: 25,
        img: "025.jpg",
        date: "Malta, March 2024",
        topic: "Conference",
        content: "Malta ClimAccelerator Demo Day",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7174101038356930564"
    },
    {
        id: 24,
        img: "024.jpg",
        date: "Denver, February 2024",
        topic: "Conference",
        content: "ETH Denver 2024",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7170337980505657346"
    },
    
    
    {
        id: 23,
        img: "023.jpg",
        date: "Belgrade, November 2023",
        topic: "Conference",
        content: "Cryptica Conference 23",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7133525557853474816/"
    },
    {
        id: 22,
        img: "022.jpg",
        date: "Singapore, November 2023",
        topic: "Conference",
        content: "Singapore FinTech Festival 2023",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7130162860617273345/"
    },
    {
        id: 21,
        img: "021.jpg",
        date: "Istanbul, November 2023",
        topic: "Conference",
        content: "Devconnect 2023",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:7130534991033180161/"
    },
      
    {
        id: 20,
        img: "020.jpg",
        date: "Lugano, October 2023",
        topic: "Conference",
        content: "Lugano Plan B Forum 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_bitcoin-activity-7121539571137302528-F_wX?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 19,
        img: "019.jpg",
        date: "Istanbul, October 2023",
        topic: "Conference",
        content: "Cosmoverse 2023",
        link: "https://www.linkedin.com/posts/darkodeuric88_cosmos-blockchain-cosmoverse2023-activity-7114698419725611008-rkQp?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 18,
        img: "018.jpg",
        date: "San Francisco, August 2023",
        topic: "Conference",
        content: "Stanford Blockchain Week 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_ethernal-team-at-stanford-university-blockchain-activity-7105093781573500928-1iTF?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 17,
        img: "017.jpg",
        date: "Tara National Park, August 2023",
        topic: "Team building",
        content: "Ethernal team conquering the Drina River!",
        link: "https://www.linkedin.com/posts/ethernal-tech_serbia-rafting-drinariver-activity-7104734911164481538-t3xs?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 16,
        img: "016.jpg",
        date: "Paris, July 2023",
        topic: "Conference",
        content: "Ethereum Community Conference 6",
        link: "https://www.linkedin.com/posts/ethernal-tech_ethcc-paris-ethereum-activity-7087483090880593920-yud5?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 15,
        img: "015.png",
        date: "Novi Sad, July 2023",
        topic: "Research",
        content: "Fractional Ownership of Non-Fungible Tokens",
        link: "https://www.linkedin.com/posts/ethernal-tech_our-team-at-ethernal-in-collaboration-with-activity-7084078829333266432-SuJz?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 14,
        img: "014.jpg",
        date: "Novi Sad, July 2023",
        topic: "Partnership",
        content: "Ethernal SRWA partnership",
        link: "https://www.linkedin.com/posts/ethernal-tech_ethernal-srwa-we-are-thrilled-to-announce-activity-7085165957706395648-5Vg6?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 13,
        img: "013.jpg",
        date: "Zurich, June 2023",
        topic: "Conference",
        content: "Point Zero Forum 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_pzf2023-activity-7079472606025834498-Fr2l?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 12,
        img: "012.jpg",
        date: "Belgrade, June 2023",
        topic: "Conference",
        content: "ETH Belgrade 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_ethbelgrade-activity-7071104239887892481-fAAf?utm_source=share&utm_medium=member_desktop"
    },
     {
         id: 11,
        img: "011.jpg",
        date: "Rotkreuz, June 2023",
        topic: "Conference",
        content: "Crypto Valley Conference 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_cvc23-activity-7070049368912883713-xRZj?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 10,
        img: "010.jpg",
        date: "Podgorica, May 2023",
        topic: "Conference",
        content: "EDCON 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_edcon2023-blockchain-ethereum-activity-7065624429682147328-TAPb?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 9,
        img: "009.jpg",
        date: "Belgrade, May 2023",
        topic: "Conference",
        content: "Tomorrow Conference 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_tmrwbelgrade-blockchain-web3-activity-7063214224306507776-RTSo?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 8,
        img: "008.png",
        date: "Lugano, April 2023",
        topic: "Conference",
        content: "B4B - Blockchain for Business 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_prender%C3%A0-il-via-domani-il-percorso-formativo-activity-7054809482270257152-zAXt?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 7,
        img: "007.jpg",
        date: "Lisbon, April 2023",
        topic: "Conference",
        content: "Zero Knowledge Summit 9",
        link: "https://www.linkedin.com/posts/ethernal-tech_zksummit-zksummit9-lisbon-activity-7049004969970081792-oqL5?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 6,
        img: "006.jpg",
        date: "Dubai, March 2023",
        topic: "Offsite",
        content: "Ethernal team in Dubai",
        link: "https://www.linkedin.com/posts/ethernal-tech_blockchain-availproject-avail-activity-7047888865059086336-hHts?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 5,
        img: "005.jpg",
        date: "Novi Sad, March 2023",
        topic: "Partnership",
        content: "Ethernal Avail partnership",
        link: "https://www.linkedin.com/posts/ethernal-tech_avail-will-operate-independently-of-polygon-activity-7045348511906770945-3W0Y?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 4,
        img: "004.jpg",
        date: "Novi Sad, March 2023",
        topic: "Partnership",
        content: "Ethernal Polygon partnership",
        link: "https://www.linkedin.com/posts/ethernal-tech_blockchain-polygonsupernets-activity-7045325877886238720-0SK_?utm_source=share&utm_medium=member_desktop"
    },

    {
         id: 3,
        img: "003.jpg",
        date: "Basel, March 2023",
        topic: "Conference",
        content: "BIS Innovation Summit 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_bis-innovation-summit-2023-technological-activity-7044711749136134144-N3zS?utm_source=share&utm_medium=member_desktop"
    },
    {
         id: 2,
        img: "002.jpg",
        date: "Dubai, March 2023",
        topic: "Conference",
        content: "Blockchain Life 2023",
        link: "https://www.linkedin.com/posts/ethernal-tech_dubai-blockchainlife-ethernal-activity-7036410709525585920-bk-n?utm_source=share&utm_medium=member_desktop"
    },
     {
         id: 1,
        img: "000.png",
        date: "Novi Sad, November 2022",
        topic: "Research",
        content: "Digital Certificates in Education",
        link: "https://ieeexplore.ieee.org/document/9983672"
    },
    {
         id: 0,
        img: "001.jpg",
        date: "Lugano, October 2022",
        topic: "Conference",
        content: "Lugano Plan B Forum 2022",
        link: "https://www.linkedin.com/feed/update/urn:li:activity:6991855806061101056?utm_source=share&utm_medium=member_desktop"
    }
]
export default dataNews