import React from 'react'
import "./services4.css"

import ResearchScene from "./ResearchSceneOpt2"
 import ConsultingScene from "./ConsultingSceneOpt2"

function Services4() {
  return (
    <section id='services' className='section_services4'>
        <div className='container services4_main'>
            <h2 className='services4_title'>Services</h2>
          
            <div className='services4_content_wrapper'>

                 <div className='services4_content_main services4_content_reverse'>

                     <div className='services4_content_main1'>
                         
                     <ConsultingScene/>
                </div>
               
                <div className='services4_content_main2'>
                     <div className='services4_content'>
                           <div className='services4_content1'>Development</div>
                           <div className='services4_content2'>Ethernal leverages on top-notch software development methods to provide end-to-end blockchain solutions.</div>
                      </div>
                 <div className='services4_content'>
                       <div className='services4_content1'>Security Audits</div>
                       <div className='services4_content2'>We assess blockchain systems for vulnerabilities, ensuring robust security through in-depth audits and proactive risk mitigation.</div>

                  </div>
                    

                </div>


            </div>
            <div className='services4_content_main'>
               
                <div className='services4_content_main2'>
                      <div className='services4_content'>
                           <div className='services4_content1'>Research</div>
                           <div className='services4_content2'>We strive for innovative solutions enabling new frontiers in Web3 technologies, eagerly tackling the most challenging problems.</div>
                      </div>
                 <div className='services4_content'>
                       <div className='services4_content1'>Consulting</div>
                       <div className='services4_content2'>We provide a range of services spanning from algorithms optimization and security auditing to design assessment and education.</div>

                  </div>
                </div>

                 <div className='services4_content_main1'>
                       <ResearchScene/>
                 </div>

            </div>

            </div>
           
        </div>
    </section>
  )
}

export default Services4
