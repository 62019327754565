import React from 'react'
import Navbar24 from '../navbar24/Navbar24'
import Header3 from '../header3/Header3'
import Partners2 from '../partners2/Partners2'
// import ServicesAnim from '../servicesAnim/ServicesAnim'
import Services4 from '../services4/Services4'
import Implementation from '../implementation/Implementation'
import Projects4 from '../projects4/Projects4'
import About from '../about/About'
import Team from '../team/Team'
import MultiChain from '../multiChain/MultiChain'
import News from '../news/News'
import Careers2 from '../careers2/Careers2'
import Contact from '../contact/Contact'
import Footer from '../footer/Footer'

function Home() {
  return (
    <section>
     
       <Navbar24/>
     <Header3/>
    <Partners2/>
    <Services4/>
     {/* <ServicesAnim/>  */}
    <Implementation />
    <Projects4/>
    <About/>
    <Team/>
    <MultiChain/>
    <News/>
    <Careers2/>
    <Contact/>
    <Footer/>     
        
    </section>
  )
}

export default Home