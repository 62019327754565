import React from 'react';
import './projects4.css';

import projectBlade from "../../assets/images/projects/blade-icon.svg"
import projectSkyline from "../../assets/images/projects/skyline-icon.png"
 import projectEdge from "../../assets/images/projects/polygonEdge-icon.svg"
import projectAvail from "../../assets/images/projects/avail-icon.svg"
import projectGravity from "../../assets/images/projects/gravityBridge-icon.svg"
import projectInterchain from "../../assets/images/projects/cosmos-icon.svg"
import projectCosmosHub from "../../assets/images/projects/cosmos-icon.svg"
 import projectApexFusion from "../../assets/images/projects/apex-icon.svg"
 import projectGearBridge from "../../assets/images/projects/gear-icon.svg"


const Projects4 = () => {


  return (
   <section id="projects" className='section-projects'>
        <div className='container projects4-main'>
          <div className='row projects4-content1'>
            <div className='col-lg-12 col-md-12 col-md-12 projects4-text'>
                <h2>Projects</h2>
              
            </div>
          </div>

               <div className='projects4-wrapper'>

                  <div class="section-grid">
                
               
                  <div className="section-grid-section">
                  <div className='section-grid-content section-A2'>
             
                       <img src={projectBlade} alt="projectBlade" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Blade Technology</h3>
                   <div className='section-copy'>Ethernal is developing Enteprise Ethereum client engineered for high performance.</div>
             
                  <div className='p4'><span><a href="https://bladeblockchain.tech/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>  
                 </div>
             
                   </div>
             
                   <div className='section-grid-content section-A2'>
             
                       <img src={projectSkyline} alt="projectSkyline" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Skyline Bridge</h3>
                   <div className='section-copy'>Skyline Bridge is a powerful, secure solution for fast cross-chain transfers, fully integrated into the Apex Fusion ecosystem. It enables seamless asset transfers across blockchain networks, meeting user and developer needs for efficiency, security, and interoperability.</div>
                    <div className='p4'><span><a href="https://skylinebridge.tech/landing" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
                     
                  </div> 
                    <div className="section-grid-section">
                              <div className='section-grid-content section-A2'>
             
                       <img src={projectEdge} alt="projectEdge" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Polygon Edge</h3>
                   <div className='section-copy'>Ethernal collaborates with Polygon on the development of Edge, the modular and extensible framework for building private or public Ethereum-compatible blockchain networks.</div>
                      <div className='p4'><span><a href="https://github.com/0xPolygon/polygon-edge" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
                 </div>
             
                   </div>
             
                   <div className='section-grid-content section-A2'>
             
                       <img src={projectAvail} alt="projectAvail" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Avail</h3>
                   <div className='section-copy'>Ethernal collaborates with Avail on the development of highly scalable data availability layer designed to power sovereign rollups, sovereign chains, Validiums and optimistic chains.</div>
                    <div className='p4'><span><a href="https://www.availproject.org/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
                     
                  </div>
                    <div className="section-grid-section">
                  
                            <div className='section-grid-content section-A2'>
             
                       <img src={projectGravity} alt="projectGravity" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Gravity Bridge</h3>
                   <div className='section-copy'>Ethernal collaborates with Informal Systems on the development of Gravity Bridge, the fully decentralized, trustless blockchain which bridges assets between the Ethereum and Cosmos ecosystems.</div>
                   <div className='p4'><span><a href="https://www.gravitybridge.net/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
             
                   <div className='section-grid-content section-A2'>
             
                       <img src={projectInterchain} alt="projectInterchain" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Interchain security</h3>
                   <div className='section-copy'>Ethernal collaborates with Informal Systems on the development of Interchain Security, the feature which allows the Cosmos Hub's validators to secure other blockchains.</div>
                    <div className='p4'><span><a href="https://interchainsecurity.dev/" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
                     
                  </div> 
                    <div className="section-grid-section">
                 
                              <div className='section-grid-content section-A2'>
             
                       <img src={projectCosmosHub} alt="projectCosmosHub" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Cosmos Hub</h3>
                   <div className='section-copy'>Ethernal collaborates with Informal Systems on the development of Cosmos Hub, the first public proof-of-stake blockchain built on top of a Byzantine Fault Tolerant consensus engine.</div>
             
                    <div className='p4'><span><a href="https://cosmos.network/features" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
             
                   <div className='section-grid-content section-A2'>
             
                       <img src={projectApexFusion} alt="projectApexFusion" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Reactor Bridge</h3>
                   <div className='section-copy'>Ethernal is developing a bridge that seamlessly connects UTXO and accounts-based blockchains, such as Cardano and Polygon. The primary objective of this undertaking is to unlock innovative opportunities for dApps while empowering the concurrent utilization of multiple blockchain capabilities.</div>
             
                    <div className='p4'><span><a href="https://developers.apexfusion.org/documentation/reactor-bridge-technical-overview" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
                     
                  </div> 
                    <div className="section-grid-section-B">
                 
                           <div className='section-grid-content section-B2'>
             
                       <img src={projectGearBridge} alt="projectGearBridge" className='section_icon'/>
             
             
                      <div className='section-A'>
                   
                    <h3 className='section-subtitle'>Gear Bridge Audit</h3>
                   <div className='section-copy'>Ethernal conducted a security audit of Gear’s implementation of the zero-knowledge prover and Ethereum smart contracts, which form part of the bridge between Vara and Ethereum.</div>
                   <div className='p4'><span><a href="https://github.com/gear-tech/gear-bridges/blob/main/audits/ethernal.pdf" target="_blank" rel="noopener noreferrer" className='no-wrap'>Read more</a></span></div>
             
                 </div>
             
                   </div>
                     
                  </div> 
             
                 
              
                </div> 
             

               </div>
        </div>
        
        </section>
  )
}

export default Projects4
